define("manage/components/metrics-view", ["exports", "@ember/component", "@glimmer/component", "@ember/object", "@ember/service", "@glimmer/tracking", "chart.js", "models/transforms/calendar-entry-list", "luxon", "@ember/template-factory"], function (_exports, _component, _component2, _object, _service, _tracking, _chart, _calendarEntryList, _luxon, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _class, _descriptor, _descriptor2, _descriptor3, _descriptor4;
  function _initializerDefineProperty(e, i, r, l) { r && Object.defineProperty(e, i, { enumerable: r.enumerable, configurable: r.configurable, writable: r.writable, value: r.initializer ? r.initializer.call(l) : void 0 }); }
  function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
  function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  function _applyDecoratedDescriptor(i, e, r, n, l) { var a = {}; return Object.keys(n).forEach(function (i) { a[i] = n[i]; }), a.enumerable = !!a.enumerable, a.configurable = !!a.configurable, ("value" in a || a.initializer) && (a.writable = !0), a = r.slice().reverse().reduce(function (r, n) { return n(i, e, r) || r; }, a), l && void 0 !== a.initializer && (a.value = a.initializer ? a.initializer.call(l) : void 0, a.initializer = void 0), void 0 === a.initializer ? (Object.defineProperty(i, e, a), null) : a; }
  function _initializerWarningHelper(r, e) { throw Error("Decorating class property failed. Please ensure that transform-class-properties is enabled and runs after the decorators transform."); }
  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    <div class="metrics-view" {{did-insert this.setup}}>
    <div class="primary d-none"></div>
    {{#if this.data}}
      <canvas {{did-insert this.renderData}} />
    {{/if}}
  </div>
  */
  {
    "id": "1GANUl+N",
    "block": "[[[11,0],[24,0,\"metrics-view\"],[4,[38,1],[[30,0,[\"setup\"]]],null],[12],[1,\"\\n  \"],[10,0],[14,0,\"primary d-none\"],[12],[13],[1,\"\\n\"],[41,[30,0,[\"data\"]],[[[1,\"    \"],[11,\"canvas\"],[4,[38,1],[[30,0,[\"renderData\"]]],null],[12],[13],[1,\"\\n\"]],[]],null],[13]],[],false,[\"div\",\"did-insert\",\"if\",\"canvas\"]]",
    "moduleName": "manage/components/metrics-view.hbs",
    "isStrictMode": false
  });
  let MetricsView = _exports.default = (_class = class MetricsView extends _component2.default {
    constructor(...args) {
      super(...args);
      _initializerDefineProperty(this, "store", _descriptor, this);
      _initializerDefineProperty(this, "intl", _descriptor2, this);
      _initializerDefineProperty(this, "data", _descriptor3, this);
      _initializerDefineProperty(this, "colors", _descriptor4, this);
    }
    toDayLabel(time, index) {
      return (0, _calendarEntryList.toDateTime)(time).toFormat('dd LLL');
    }
    get defaultStart() {
      return this.defaultEnd.minus({
        months: 1
      });
    }
    get defaultEnd() {
      return _luxon.DateTime.now().endOf('day');
    }
    get start() {
      return (0, _calendarEntryList.toDateTime)(this.args.start ?? this.defaultStart);
    }
    get end() {
      return (0, _calendarEntryList.toDateTime)(this.args.end ?? this.defaultEnd);
    }
    fixGaps(data) {
      let prevItem = this.start;
      let newData = [];
      if (!data?.length) {
        return newData;
      }
      let index = 0;
      const lastTime = (0, _calendarEntryList.toDateTime)(data[data.length - 1].time);
      if (data.length > 0 && !lastTime.hasSame(this.end, 'day')) {
        data = data?.toArray?.() ?? data ?? [];
        data.push({
          value: 0,
          time: this.end
        });
      }
      while (prevItem < this.end && data.length > index) {
        let record = data[index];
        index++;
        let nextDate = (0, _calendarEntryList.toDateTime)(record.time);
        let fillRecord = {
          value: 0,
          time: prevItem.plus({
            day: 1
          })
        };
        while (!nextDate.hasSame(fillRecord.time, 'day') && nextDate > fillRecord.time) {
          newData.push({
            ...fillRecord
          });
          fillRecord.time = fillRecord.time.plus({
            days: 1
          });
        }
        newData.push(record);
        prevItem = nextDate;
      }
      return newData;
    }
    async setup(element) {
      if (!this.args.name || !this.args.type || !this.args.value) {
        return;
      }
      this.data = this.fixGaps(this.args.value).map((a, i) => ({
        value: a.value,
        label: this.toDayLabel(a.time, i)
      }));
      const primary = getComputedStyle(element.querySelector(".primary"));
      this.colors.primary = {
        backgroundColor: primary["background-color"],
        borderColor: primary["background-color"],
        borderWidth: parseInt(primary["border-bottom-width"]),
        hoverBorderWidth: parseInt(primary["border-bottom-width"])
      };
    }
    async renderData(element) {
      const chart = new _chart.Chart(element, {
        type: 'line',
        data: {
          labels: this.data.map(a => a.label),
          datasets: [{
            label: this.intl.t('_dashboard.map-views'),
            data: this.data.map(a => a.value)
          }]
        },
        options: {
          responsive: true,
          maintainAspectRatio: false,
          elements: {
            point: {
              radius: 1,
              hitRadius: 20,
              ...this.colors.primary
            },
            line: this.colors.primary
          },
          scales: {
            x: {
              display: false
            },
            y: {
              display: true
            }
          },
          plugins: {
            legend: {
              display: false
            }
          }
        }
      });
      this.chart = chart;
      element.chart = chart;
    }
  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "store", [_service.service], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "intl", [_service.service], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "data", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "colors", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return {};
    }
  }), _applyDecoratedDescriptor(_class.prototype, "setup", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "setup"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "renderData", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "renderData"), _class.prototype)), _class);
  (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, MetricsView);
});
define("manage/components/input/default-models", ["exports", "@ember/component", "@glimmer/component", "@glimmer/tracking", "@ember/service", "models/transforms/default-models", "@ember/object", "@ember/template-factory"], function (_exports, _component, _component2, _tracking, _service, _defaultModels, _object, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _class, _descriptor, _descriptor2, _descriptor3, _class2, _descriptor4, _descriptor5, _descriptor6, _descriptor7, _descriptor8, _descriptor9, _descriptor10, _descriptor11, _descriptor12, _descriptor13;
  function _initializerDefineProperty(e, i, r, l) { r && Object.defineProperty(e, i, { enumerable: r.enumerable, configurable: r.configurable, writable: r.writable, value: r.initializer ? r.initializer.call(l) : void 0 }); }
  function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
  function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  function _applyDecoratedDescriptor(i, e, r, n, l) { var a = {}; return Object.keys(n).forEach(function (i) { a[i] = n[i]; }), a.enumerable = !!a.enumerable, a.configurable = !!a.configurable, ("value" in a || a.initializer) && (a.writable = !0), a = r.slice().reverse().reduce(function (r, n) { return n(i, e, r) || r; }, a), l && void 0 !== a.initializer && (a.value = a.initializer ? a.initializer.call(l) : void 0, a.initializer = void 0), void 0 === a.initializer ? (Object.defineProperty(i, e, a), null) : a; }
  function _initializerWarningHelper(r, e) { throw Error("Decorating class property failed. Please ensure that transform-class-properties is enabled and runs after the decorators transform."); }
  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    <div class="input-default-models" {{did-insert this.setup}} {{did-update this.setup @team}}>
    {{#each this.pairs as |pair|}}
      <div class="model-value">
        <Label @value={{pair.name}} as |id|>
          <Input::Select id={{id}} @list={{pair.valueList}} @value={{pair.value}} @onChange={{fn this.change pair.name}} />
        </Label>
  
        {{#if pair.value}}
          <button class="btn btn-danger btn-remove" type="button" {{on "click" (fn this.clear pair)}}>
            <FaIcon @icon="xmark" />
          </button>
        {{/if}}
      </div>
    {{/each}}
  </div>
  */
  {
    "id": "BiNG5Gw/",
    "block": "[[[11,0],[24,0,\"input-default-models\"],[4,[38,1],[[30,0,[\"setup\"]]],null],[4,[38,2],[[30,0,[\"setup\"]],[30,1]],null],[12],[1,\"\\n\"],[42,[28,[37,4],[[28,[37,4],[[30,0,[\"pairs\"]]],null]],null],null,[[[1,\"    \"],[10,0],[14,0,\"model-value\"],[12],[1,\"\\n      \"],[8,[39,5],null,[[\"@value\"],[[30,2,[\"name\"]]]],[[\"default\"],[[[[1,\"\\n        \"],[8,[39,6],[[16,1,[30,3]]],[[\"@list\",\"@value\",\"@onChange\"],[[30,2,[\"valueList\"]],[30,2,[\"value\"]],[28,[37,7],[[30,0,[\"change\"]],[30,2,[\"name\"]]],null]]],null],[1,\"\\n      \"]],[3]]]]],[1,\"\\n\\n\"],[41,[30,2,[\"value\"]],[[[1,\"        \"],[11,\"button\"],[24,0,\"btn btn-danger btn-remove\"],[24,4,\"button\"],[4,[38,10],[\"click\",[28,[37,7],[[30,0,[\"clear\"]],[30,2]],null]],null],[12],[1,\"\\n          \"],[8,[39,11],null,[[\"@icon\"],[\"xmark\"]],null],[1,\"\\n        \"],[13],[1,\"\\n\"]],[]],null],[1,\"    \"],[13],[1,\"\\n\"]],[2]],null],[13]],[\"@team\",\"pair\",\"id\"],false,[\"div\",\"did-insert\",\"did-update\",\"each\",\"-track-array\",\"label\",\"input/select\",\"fn\",\"if\",\"button\",\"on\",\"fa-icon\"]]",
    "moduleName": "manage/components/input/default-models.hbs",
    "isStrictMode": false
  });
  let Pair = (_class = class Pair {
    constructor(props) {
      _initializerDefineProperty(this, "name", _descriptor, this);
      _initializerDefineProperty(this, "value", _descriptor2, this);
      _initializerDefineProperty(this, "valueList", _descriptor3, this);
      this.name = props.name;
      this.value = props.value;
      this.valueList = props.valueList;
    }
  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "name", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return "";
    }
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "value", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return "";
    }
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "valueList", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return "";
    }
  })), _class);
  let InputDefaultModelsComponent = _exports.default = (_class2 = class InputDefaultModelsComponent extends _component2.default {
    constructor(...args) {
      super(...args);
      _initializerDefineProperty(this, "store", _descriptor4, this);
      _initializerDefineProperty(this, "_value", _descriptor5, this);
      _initializerDefineProperty(this, "map", _descriptor6, this);
      _initializerDefineProperty(this, "campaign", _descriptor7, this);
      _initializerDefineProperty(this, "calendar", _descriptor8, this);
      _initializerDefineProperty(this, "newsletter", _descriptor9, this);
      _initializerDefineProperty(this, "mapList", _descriptor10, this);
      _initializerDefineProperty(this, "campaignList", _descriptor11, this);
      _initializerDefineProperty(this, "calendarList", _descriptor12, this);
      _initializerDefineProperty(this, "newsletterList", _descriptor13, this);
    }
    get models() {
      return this.args.models || _defaultModels.defaultModelNames;
    }
    get value() {
      if (this._value) {
        return this._value;
      }
      return this.args.value;
    }
    get team() {
      return this.args.team?.id;
    }
    get pairs() {
      const result = [];
      for (const name of this.models) {
        result.push(new Pair({
          name,
          value: this[name],
          valueList: this[`${name}List`]
        }));
      }
      return result;
    }
    change(name, value) {
      if (!this._value) {
        this._value = new _defaultModels.DefaultModels(this.value);
      }
      this._value[name] = value.id;
      return this.args.onChange(this.value);
    }
    clear(pair) {
      if (!this._value) {
        this._value = new _defaultModels.DefaultModels(this.value);
      }
      this._value[pair.name] = "";
      pair.value = "";
      return this.args.onChange(this.value);
    }
    async setup() {
      const value = this.args.value;
      for (const name of this.models) {
        if (value?.[name]) {
          let resolvedValue = this.store.peekRecord(name, value[name]);
          if (!resolvedValue) {
            try {
              resolvedValue = await this.store.findRecord(name, value[name]);
            } catch (err) {
              console.error(err);
            }
          }
          this[name] = resolvedValue;
        }
        this[`${name}List`] = await this.store.query(name, {
          team: this.team
        });
      }
    }
  }, (_descriptor4 = _applyDecoratedDescriptor(_class2.prototype, "store", [_service.service], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor5 = _applyDecoratedDescriptor(_class2.prototype, "_value", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor6 = _applyDecoratedDescriptor(_class2.prototype, "map", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return null;
    }
  }), _descriptor7 = _applyDecoratedDescriptor(_class2.prototype, "campaign", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return null;
    }
  }), _descriptor8 = _applyDecoratedDescriptor(_class2.prototype, "calendar", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return null;
    }
  }), _descriptor9 = _applyDecoratedDescriptor(_class2.prototype, "newsletter", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return null;
    }
  }), _descriptor10 = _applyDecoratedDescriptor(_class2.prototype, "mapList", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return [];
    }
  }), _descriptor11 = _applyDecoratedDescriptor(_class2.prototype, "campaignList", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return [];
    }
  }), _descriptor12 = _applyDecoratedDescriptor(_class2.prototype, "calendarList", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return [];
    }
  }), _descriptor13 = _applyDecoratedDescriptor(_class2.prototype, "newsletterList", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return [];
    }
  }), _applyDecoratedDescriptor(_class2.prototype, "change", [_object.action], Object.getOwnPropertyDescriptor(_class2.prototype, "change"), _class2.prototype), _applyDecoratedDescriptor(_class2.prototype, "clear", [_object.action], Object.getOwnPropertyDescriptor(_class2.prototype, "clear"), _class2.prototype), _applyDecoratedDescriptor(_class2.prototype, "setup", [_object.action], Object.getOwnPropertyDescriptor(_class2.prototype, "setup"), _class2.prototype)), _class2);
  (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, InputDefaultModelsComponent);
});